// constants regardless of environment

export const APPNAME = 'store-locator';

export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const DEVELOPMENT = 'development';
export const UNKNOWN = 'unknown';

export const SENTRY_DSN =
  'https://8f9fabc3389c474392932406d675e30b@sentry.io/1513824';
