import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import SearchContainer from 'containers/SearchContainer';
import LocationListContainer from 'containers/LocationListContainer';
import MobileViewContainer from 'containers/MobileViewContainer';
import { Tabs, Tab, ContentTab } from 'styles/styledComponents';

export default class LocationsContainer extends React.Component {
  state = {
    activeTab: 0
  };

  selectTab = event => {
    const tab = parseInt(event.target.id, 0);
    if (tab !== this.state.activeTab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const {
      locations,
      handleToggleClose,
      handleToggleOpen,
      revertToDefault,
      settings,
      updateCenter,
      updateSearchResults,
      updateZoom,
      locationNearby,
      chowNowId,
      center,
      children
    } = this.props;

    const { activeTab } = this.state;

    return (
      <MobileViewContainer id="locationsContainer">
        {settings.displaySearch && locations?.length > 0 && (
          <SearchContainer
            locations={locations}
            revertToDefault={revertToDefault}
            updateCenter={updateCenter}
            updateSearchResults={updateSearchResults}
            updateZoom={updateZoom}
            locationNearby={locationNearby}
            defaultLat={center.lat}
            defaultLng={center.lng}
          />
        )}

        <MediaQuery query="(min-width: 768px)">
          <LocationListContainer
            handleToggleClose={handleToggleClose}
            handleToggleOpen={handleToggleOpen}
            locations={locations}
            updateCenter={updateCenter}
            updateZoom={updateZoom}
            displayMoreInfo={settings.displayMoreInfo}
            displayMultiButton={settings.displayMultiButton}
            multiButton={settings.multiButton}
            chowNowId={chowNowId}
          />
        </MediaQuery>

        <MediaQuery query="(max-width: 767px)">
          <Tabs>
            <Tab onClick={this.selectTab} active={activeTab === 0} id={0}>
              LIST
            </Tab>
            <Tab onClick={this.selectTab} active={activeTab === 1} id={1}>
              MAP
            </Tab>
          </Tabs>

          <ContentTab active={activeTab === 0}>
            <LocationListContainer
              handleToggleClose={handleToggleClose}
              handleToggleOpen={handleToggleOpen}
              locations={locations}
              updateCenter={updateCenter}
              updateZoom={updateZoom}
              displayMoreInfo={settings.displayMoreInfo}
              displayMultiButton={settings.displayMultiButton}
              multiButton={settings.multiButton}
              chowNowId={chowNowId}
            />
          </ContentTab>

          <ContentTab active={activeTab === 1}>{children}</ContentTab>
        </MediaQuery>
      </MobileViewContainer>
    );
  }
}

LocationsContainer.propTypes = {
  chowNowId: PropTypes.string,
  handleToggleClose: PropTypes.func.isRequired,
  handleToggleOpen: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  revertToDefault: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  updateCenter: PropTypes.func.isRequired,
  updateSearchResults: PropTypes.func.isRequired,
  updateZoom: PropTypes.func.isRequired,
  locationNearby: PropTypes.bool.isRequired,
  center: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

LocationsContainer.defaultProps = {
  chowNowId: null,
  locations: []
};
