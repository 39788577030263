import React from 'react';
import PropTypes from 'prop-types';
import LocationListItemComponent from 'components/LocationListItemComponent';
import { HardBreak, LocationList } from 'styles/styledComponents';

import { placesZoomLevel } from 'constants/defaultMapProps';

import multiButtonProps, {
  DEFAULT_MULTI_BUTTON_PROPS
} from 'constants/multiButtonProps';

export default class LocationListContainer extends React.Component {
  handleNameClick = location => {
    const { updateCenter, updateZoom, handleToggleOpen } = this.props;
    updateCenter(parseFloat(location.lat), parseFloat(location.lng));
    updateZoom(placesZoomLevel);
    handleToggleOpen(location.id);
  };

  renderListItems = () => {
    const {
      displayMoreInfo,
      displayMultiButton,
      multiButton,
      locations,
      chowNowId
    } = this.props;
    return locations.map((location, index) => {
      return [
        <HardBreak
          className="locationListItem-break hr-store-locator"
          key={`${index}-hr`}
        />,
        <LocationListItemComponent
          displayMoreInfo={displayMoreInfo}
          displayMultiButton={displayMultiButton}
          multiButton={multiButton}
          key={index}
          handleNameClick={this.handleNameClick}
          location={location}
          chowNowId={chowNowId}
        />
      ];
    });
  };

  render() {
    return (
      <LocationList className="locationList">
        {this.renderListItems()}
      </LocationList>
    );
  }
}

LocationListContainer.propTypes = {
  chowNowId: PropTypes.string,
  displayMoreInfo: PropTypes.bool.isRequired,
  displayMultiButton: PropTypes.bool.isRequired,
  handleToggleOpen: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  multiButton: PropTypes.shape(multiButtonProps),
  updateCenter: PropTypes.func.isRequired,
  updateZoom: PropTypes.func.isRequired
};

LocationListContainer.defaultProps = {
  chowNowId: null,
  locations: [],
  multiButton: { ...DEFAULT_MULTI_BUTTON_PROPS }
};
