import React from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from '@react-google-maps/api';

import LocationNameDisplay from 'components/LocationNameDisplay';
import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import { sanitizeValue, sanitizeUrl } from 'utils/sanitize';
import { openUrl } from 'utils/window';

import { MarkerContainer, MarkerContent } from 'styles/styledComponents';

export default class MarkerComponent extends React.Component {
  handleMoreInfo = () => {
    const { location } = this.props;
    openUrl(sanitizeUrl(location.url));
  };

  getGoogleMapsUrl = () => {
    const { location } = this.props;
    const googleMapsUri = 'https://www.google.com/maps/search/?api=1';
    let searchUrl = '';
    const lat = parseFloat(location.lat);
    const lng = parseFloat(location.lng);
    if (location.google_place_id) {
      searchUrl = `${googleMapsUri}&query=${lat},${lng}&query_place_id=${location.google_place_id}`;
    } else {
      const urlSafeAddress = encodeURI(location.address);
      searchUrl = `${googleMapsUri}&query=${urlSafeAddress}`;
    }
    return searchUrl;
  };

  getMarkerProps = () => {
    const { location, markerIcon, handleToggleOpen } = this.props;
    const markerProps = {
      position: {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng)
      },
      onClick: () => handleToggleOpen(location.id),
      title: sanitizeValue(location.name)
    };
    if (markerIcon !== 'https://getbento.imgix.net/' && markerIcon) {
      markerProps.icon = markerIcon;
    }
    return markerProps;
  };

  displayCustomButton = () => {
    const { location } = this.props;
    return (
      location.store_locator_custom_button_text &&
      location.store_locator_custom_button_url
    );
  };

  render() {
    const { activeId, handleToggleClose, location, displayMoreInfo } =
      this.props;
    const sanitizedPhoneNumber = sanitizeValue(location.phone_number);
    const sanitizedName = sanitizeValue(location.name);
    const sanitizedUrl = sanitizeUrl(location.url);
    const sanitizedCustomButtonText = sanitizeValue(
      location.store_locator_custom_button_text
    );
    const sanitizedCustomButtonUrl = sanitizeValue(
      location.store_locator_custom_button_url
    );
    const markerProps = this.getMarkerProps();
    return (
      <Marker {...markerProps}>
        {activeId === location.id && (
          <InfoWindow
            onCloseClick={handleToggleClose}
            position={markerProps.position}
          >
            <MarkerContainer id="infoWindow">
              <LocationNameDisplay
                id="infoWindow-title"
                name={sanitizedName}
                url={sanitizedUrl}
              />
              {location.address && (
                <MarkerContent
                  href={this.getGoogleMapsUrl()}
                  target="_blank"
                  id="infoWindow-address"
                  rel="noreferrer"
                >
                  {location.street}
                  <br id="infoWindow-address-break" />
                  {location.city}, {location.state} {location.postal_code}
                </MarkerContent>
              )}
              <br id="infoWindow-break" />
              {location.phone_number && (
                <React.Fragment>
                  <MarkerContent
                    href={`tel:${sanitizedPhoneNumber}`}
                    id="infoWindow-phone"
                  >
                    {sanitizedPhoneNumber}
                  </MarkerContent>
                  <br id="infoWindow-break" />
                </React.Fragment>
              )}
              {displayMoreInfo && (
                <Button
                  label="Location Details"
                  handleClick={this.handleMoreInfo}
                  id="infoWindow-moreInfo"
                  addTopMargin
                />
              )}
              {this.displayCustomButton() && (
                <LinkButton
                  className="locationListItem-customButton locationListItem-link"
                  label={sanitizedCustomButtonText}
                  addTopMargin
                  href={sanitizedCustomButtonUrl}
                  isExternal
                />
              )}
            </MarkerContainer>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

MarkerComponent.propTypes = {
  activeId: PropTypes.number,
  displayMoreInfo: PropTypes.bool.isRequired,
  handleToggleClose: PropTypes.func.isRequired,
  handleToggleOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  markerIcon: PropTypes.string
};
