import 'babel-polyfill';
import 'isomorphic-fetch';

import React from 'react';
import ReactDOM from 'react-dom';

import StoreLocatorContainer from 'containers/StoreLocatorContainer';
import { initializeSentry } from 'config/sentry';
require('es6-promise').polyfill();

initializeSentry();

window.storeLocatorConfig = function (props) {
  ReactDOM.render(
    <StoreLocatorContainer {...props} />,
    document.getElementById('storeLocator')
  );
};
