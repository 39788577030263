import styled from 'styled-components';

export const HardBreak = styled.hr`
  margin: 0 2rem 2rem;
  width: initial;
  border: none;
  height: 1px;
`;

export const LocationList = styled.div`
  margin-bottom: 0.75rem;
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LocationListItem = styled.div`
  margin-bottom: 2rem;
  margin-top: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  text-align: left;
`;

export const FlexSection = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

export const LocationListItemHours = styled.div`
  * {
    padding: 0;
    margin: 0;
  }
  textalign: left;
`;

export const ScreenReaderMainTitle = styled.h1`
  position: absolute;
  left: -9999px;
`;

export const LocationName = styled.h2`
  margin-bottom: 0;
  text-align: left;
  text-transform: uppercase;
  padding-bottom: 0.15rem;
  font-size: 20px;
  & > a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    &:hover {
      opacity: 70%;
    }
  }
`;

const DEFAULT_MARGIN = '0.75rem';

export const StyledButton = styled.button`
  display: block;
  width: fit-content;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 0.5rem 0.5rem;
  margin-top: ${props => (props.addTopMargin ? DEFAULT_MARGIN : '')};
  margin-right: ${props => (props.addRightMargin ? DEFAULT_MARGIN : '')};
`;

export const Label = styled.label`
  text-align: left;
  margin-bottom: 0.25rem;
`;

export const MarkerContainer = styled.div`
  width: 100%;
  max-width: 200px;
  text-align: left;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
`;

export const MarkerContent = styled.a`
  display: inline-block;
  margin-top: 0.5rem;
`;

export const SearchContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;
  flex-shrink: 0;
`;

export const SearchError = styled.div`
  margin-top: 0.25rem;
`;

export const SearchResetSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LinkButton = styled.button`
  margin-top: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const LocationsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding-bottom: 1rem;
`;

export const LocationsContainer = styled(LocationsContainerMobile)`
  width: 450px;
  padding-bottom: 2rem;
  max-height: 85vh;
`;

export const StoreLocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: stretch;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Tab = styled.button`
  border: none;
  background-color: transparent;
  border-bottom: 0.4rem solid
    ${props => (props.active ? '#000' : 'transparent')};
  cursor: pointer;
  color: black;
  font-weight: bold;
  height: 4rem;
  width: 100%;
  overflow: none;
`;

export const ContentTab = styled.div`
  ${props => (props.active ? '' : 'display:none')};
  overflow-x: hidden;
`;
