import React from 'react';
import PropTypes from 'prop-types';

import {
  BUTTON_BRAND_CLASSES,
  BUTTON_BRAND_ALT_COLOR_CLASS
} from 'constants/buttonClasses';

import { StyledButton } from 'styles/styledComponents';

const LinkButton = ({
  addTopMargin,
  addRightMargin,
  altColor,
  className,
  href,
  isExternal,
  label,
  target,
  ...rest
}) => (
  <StyledButton
    as="a"
    href={href}
    target={target}
    rel={isExternal ? 'noopener' : ''}
    addTopMargin={addTopMargin}
    addRightMargin={addRightMargin}
    tabIndex="0"
    className={`${BUTTON_BRAND_CLASSES} ${className} ${
      altColor ? BUTTON_BRAND_ALT_COLOR_CLASS : ''
    }`}
    {...rest}
  >
    {label}
  </StyledButton>
);

LinkButton.propTypes = {
  addTopMargin: PropTypes.bool,
  addRightMargin: PropTypes.bool,
  altColor: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  label: PropTypes.string.isRequired,
  target: PropTypes.string
};

LinkButton.defaultProps = {
  addTopMargin: false,
  addRightMargin: false,
  altColor: false,
  className: '',
  isExternal: false,
  target: '_blank'
};

export default LinkButton;
