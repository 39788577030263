import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import MarkerComponent from 'components/MarkerComponent';
import { GOOGLE_MAPS_API_KEY } from 'constants/googleMaps';

const MapComponent = ({
  activeId,
  center,
  defaultOptions,
  displayMoreInfo,
  handleToggleClose,
  handleToggleOpen,
  locations,
  markerIcon,
  zoom
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const renderMarkers = () => {
    return locations.map((location, index) => {
      return (
        <MarkerComponent
          activeId={activeId}
          displayMoreInfo={displayMoreInfo}
          key={index}
          handleToggleClose={handleToggleClose}
          handleToggleOpen={handleToggleOpen}
          location={location}
          markerIcon={markerIcon}
        />
      );
    });
  };

  const containerStyle = {
    width: '100vw',
    height: '100vh'
  };

  return isLoaded ? (
    <GoogleMap
      center={center}
      mapContainerClassName="mapContainer"
      mapContainerStyle={containerStyle}
      options={defaultOptions}
      zoom={zoom}
    >
      {renderMarkers()}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapComponent;

MapComponent.propTypes = {
  activeId: PropTypes.number,
  center: PropTypes.object.isRequired,
  defaultOptions: PropTypes.object.isRequired,
  displayMoreInfo: PropTypes.bool.isRequired,
  handleToggleClose: PropTypes.func.isRequired,
  handleToggleOpen: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  markerIcon: PropTypes.string,
  zoom: PropTypes.number.isRequired
};
