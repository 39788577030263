import {
  LATITUDE_DELTA,
  LONGITUDE_DELTA,
  MIN_LATITUDE,
  MIN_LONGITUDE,
  MAX_LATITUDE,
  MAX_LONGITUDE
} from 'constants/mapbox';

export function getBbox(latitude, longitude) {
  return [
    Math.max(longitude - LONGITUDE_DELTA, MIN_LONGITUDE),
    Math.max(latitude - LATITUDE_DELTA, MIN_LATITUDE),
    Math.min(longitude + LONGITUDE_DELTA, MAX_LONGITUDE),
    Math.min(latitude + LATITUDE_DELTA, MAX_LATITUDE)
  ];
}
