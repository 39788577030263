import React from 'react';
import PropTypes from 'prop-types';
import { LocationName } from 'styles/styledComponents';

const LocationNameDisplayComponent = ({ name, url, ...extendedProps }) => (
  <LocationName className="location-name" {...extendedProps}>
    <a href={url}>{name}</a>
  </LocationName>
);

LocationNameDisplayComponent.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default LocationNameDisplayComponent;
