import React from 'react';
import PropTypes from 'prop-types';
import MapComponent from 'components/MapComponent';

class MapContainer extends React.Component {
  getDefaultOptions = () => {
    const { defaultStyles } = this.props;
    return {
      styles: defaultStyles,
      streetViewControl: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      rotateControl: false,
      fullscreenControl: false
    };
  };

  render() {
    const {
      activeId,
      center,
      handleToggleClose,
      handleToggleOpen,
      locations,
      markerIcon,
      zoom,
      settings
    } = this.props;
    return (
      <MapComponent
        className="mapContainer"
        center={center}
        zoom={zoom}
        locations={locations}
        isMarkerShown
        handleToggleOpen={handleToggleOpen}
        handleToggleClose={handleToggleClose}
        activeId={activeId}
        defaultOptions={this.getDefaultOptions()}
        markerIcon={markerIcon}
        displayMoreInfo={settings.displayMoreInfo}
      />
    );
  }
}

MapContainer.propTypes = {
  activeId: PropTypes.number,
  center: PropTypes.object.isRequired,
  defaultStyles: PropTypes.arrayOf(PropTypes.object),
  handleToggleClose: PropTypes.func.isRequired,
  handleToggleOpen: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  markerIcon: PropTypes.string,
  settings: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired
};

export default MapContainer;
