import { getReservationsModal } from 'utils/window';

export const OPEN_ERROR_MESSAGE = 'Reservations modal utility is not exposed.';

export const openReservationsModal = locationSlug => {
  const reservationsModal = getReservationsModal();

  if (!reservationsModal) {
    console.error(OPEN_ERROR_MESSAGE);
    return;
  }

  reservationsModal.open(locationSlug);
};
