export const openNewTab = url => {
  const win = window.open(url, '_blank');
  win.focus();
};

export const openUrl = url => {
  const win = window.open(url, '_self');
  win.focus();
};

export const getReservationsModal = () => {
  if (!window.reservationsModal) {
    return null;
  }

  return window.reservationsModal;
};
