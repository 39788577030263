export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiZ2V0YmVudG8iLCJhIjoiY2s5dmwzdDJzMDBjMjNmbmp4Z21hOGE5OSJ9.K9HkkmazQcyhd1Ok3w6Q6w';
export const MAPBOX_GEOCODER = 'MapboxGeocoder';
export const MAPBOX_GEOCODER_ID = `#${MAPBOX_GEOCODER}`;
export const MAPBOX_GEOCODER_INPUT_ID = 'MapboxGeocoderInput';
export const MAPBOX_GEOCODER_INPUT_CLASS = '.mapboxgl-ctrl-geocoder--input';
export const MAPBOX_GEOCODER_INPUT_PLACEHOLDER = 'Enter a location';

export const MAPBOX_GEOCODER_LABEL = 'Enter zip code, city, or full address';
export const MAPBOX_GEOCODER_NOT_AVAILABLE_MESSAGE =
  'Search is not available at this time.';

// Experimental values taken from the MapBox playground,
// which can wrap the entire US or even more
// https://docs.mapbox.com/playground/geocoding/
export const LATITUDE_DELTA = 40;
export const LONGITUDE_DELTA = 50;

// Valid coordinates must be within the following range:
//    - latitude coordinate is between -90 and 90
//    - longitude coordinate is between -180 and 180
// Ref: https://support.google.com/maps/answer/18539
export const MIN_LATITUDE = -90;
export const MAX_LATITUDE = 90;
export const MIN_LONGITUDE = -180;
export const MAX_LONGITUDE = 180;
