import React from 'react';
import PropTypes from 'prop-types';
import * as Geocoder from '@mapbox/mapbox-gl-geocoder';

import SearchComponent from 'components/SearchComponent';
import {
  MAPBOX_ACCESS_TOKEN,
  MAPBOX_GEOCODER_ID,
  MAPBOX_GEOCODER_INPUT_ID,
  MAPBOX_GEOCODER_INPUT_CLASS,
  MAPBOX_GEOCODER_INPUT_PLACEHOLDER
} from 'constants/mapbox';
import { getBbox } from 'utils/searchUtils';

class SearchContainer extends React.Component {
  state = {
    isSearchUsable: false
  };

  componentDidMount() {
    this.setupSearch();
  }

  componentWillUnmount() {
    this.geocoder.off('result', this.handleResult);
  }

  initializeGeocoder() {
    const { defaultLat, defaultLng } = this.props;

    const options = {
      accessToken: MAPBOX_ACCESS_TOKEN,
      placeholder: MAPBOX_GEOCODER_INPUT_PLACEHOLDER,
      trackProximity: false,
      proximity: 'ip',
      types:
        'address, postcode, region, district, locality, neighborhood, place'
    };

    if (defaultLng && defaultLat) {
      // The coordinates in the bbox array represent the 2 opposite vertices of a quadrilateral on the map.
      // Location suggestions inside that area will be displayed on the search bar as the user type.
      options.bbox = getBbox(defaultLat, defaultLng);
    }

    const geocoder = new Geocoder(options);

    return geocoder;
  }

  setupSearch = () => {
    const geocoder = this.initializeGeocoder();

    geocoder.on('result', this.handleResult);

    // addTo will throw if it can't add the geocoder to the DOM
    // or the accessToken provided isn't valid so this is the
    // best opportunity to bail
    try {
      geocoder.addTo(MAPBOX_GEOCODER_ID);
    } catch (error) {
      console.error(error);
      return;
    }

    // a11y is sometimes hacky; add an id so the input is connected to a label
    geocoder._inputEl.setAttribute('id', MAPBOX_GEOCODER_INPUT_ID);
    this.geocoder = geocoder;

    this.setUpContainerAccessibility();

    this.setState({ isSearchUsable: true });
  };

  setUpContainerAccessibility = () => {
    const MapboxGeocoderInput = document.getElementById('MapboxGeocoderInput');
    if (MapboxGeocoderInput) {
      MapboxGeocoderInput.role = 'combobox';
      MapboxGeocoderInput.ariaLabel = 'Location Input';
      MapboxGeocoderInput.setAttribute('aria-activedescendant', 'active_item');
    }

    const suggestionsInput = document.getElementsByClassName('suggestions');
    if (suggestionsInput.length) {
      suggestionsInput[0].role = 'listbox';
      suggestionsInput[0].ariaLabel = 'Locations Suggestions';
    }
  };

  /**
   * Accept a result object and return an object with the correct shape
   */
  searchResultPayload = result => {
    const { geometry } = result;
    const [lng, lat] = geometry.coordinates;

    const searchResult = {
      geometry: {
        location: {
          lat: () => lat,
          lng: () => lng
        }
      }
    };

    return searchResult;
  };

  handleResult = ({ result }) => {
    const { updateSearchResults } = this.props;

    const searchResult = this.searchResultPayload(result);
    updateSearchResults(searchResult);
  };

  manualSearch = () => {
    const selector = `${MAPBOX_GEOCODER_ID} ${MAPBOX_GEOCODER_INPUT_CLASS}`;
    const input = document.querySelector(selector).value.trim();

    const isInputInvalid =
      input === '' || input.length <= this.geocoder.getMinLength();

    if (isInputInvalid) {
      return;
    }

    this.geocoder.query(input);
  };

  render() {
    return (
      <SearchComponent
        handleManualSearch={this.manualSearch}
        locationNearby={this.props.locationNearby}
        isSearchUsable={this.state.isSearchUsable}
      />
    );
  }
}

SearchContainer.propTypes = {
  revertToDefault: PropTypes.func.isRequired,
  updateSearchResults: PropTypes.func.isRequired,
  locationNearby: PropTypes.bool.isRequired,
  defaultLat: PropTypes.number.isRequired,
  defaultLng: PropTypes.number.isRequired
};

export default SearchContainer;
