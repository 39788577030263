/**
 * This function calculates if an event should be to send to sentry
 * or if we want to exclude it.
 *
 * @param {Object} event Sentry event
 * @param {Object} eventMatcher Matcher settings
 * @param {string} [eventMatcher.type] The event type. For example: TypeError
 * @param {string} [eventMatcher.value] The event message. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.message] The event message in case is not an exception. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.file] The source file path or string. For example: http://bentobox.com/file.js or just file.js
 * @param {string} [eventMatcher.missingStackTrace] If set to true, the expectation is that the event doesn't have a stack trace, if set to false (the default), the event must have a stacktrace.
 *
 * @returns boolean
 */
export function excludeEvent(event, eventMatcher) {
  // Usage restrictions
  const matcherProperties = Object.getOwnPropertyNames(eventMatcher);
  if (
    matcherProperties.includes('file') &&
    matcherProperties.includes('missingStackTrace')
  ) {
    throw Error(
      'This two properties can not be set at the same time, please only use one: "file", "missingStackTrace"'
    );
  }

  try {
    if (event.exception) {
      let typeAndValueMatches = false;
      let fileMatches = false;
      let passesStackTraceCheck = true;

      const sentryExcept =
        event.exception.values[event.exception.values.length - 1];

      if (eventMatcher.missingStackTrace && sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (!eventMatcher.missingStackTrace && !sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (eventMatcher.type && eventMatcher.value) {
        const typeMatches = sentryExcept.type.includes(eventMatcher.type);
        const valueMatches = sentryExcept.value.includes(eventMatcher.value);
        typeAndValueMatches = typeMatches && valueMatches;
      }

      if (eventMatcher.file) {
        const frame =
          sentryExcept.stacktrace?.frames[
            sentryExcept.stacktrace?.frames.length - 1
          ];
        if (frame) {
          const filenameMatches = (frame.filename ?? '').includes(
            eventMatcher.file
          );
          const absPathMatches = (frame.abs_path ?? '').includes(
            eventMatcher.file
          );
          fileMatches = filenameMatches || absPathMatches;
        }
        return typeAndValueMatches && fileMatches;
      }

      return typeAndValueMatches && passesStackTraceCheck;
    } else {
      if (eventMatcher.message && event.message) {
        return event.message.includes(eventMatcher.message);
      }
    }
  } catch (error) {
    return false;
  }
  return false;
}

export function beforeSend(event) {
  const ignoreEvent = excludeEventMatcherList.some(matcher =>
    excludeEvent(event, matcher)
  );

  if (ignoreEvent) {
    return null;
  }
  return event;
}

/**
 * If you want to add a new matcher,
 * please make sure all test pass on "sentryUtils.test.js" file.
 *
 * Also, don't forget to add an example of the event on "excludeEventRealEvents.js" file,
 * The test will automatically pick it up and run the test applying the matchers.
 */
export const excludeEventMatcherList = [
  {
    // https://bentobox.sentry.io/issues/3125991428/events/latest/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value: "Cannot read properties of undefined (reading 'planRemediations')",
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/1127627968/events/latest/?environment=production&project=1513824&referrer=latest-event
    type: 'Error',
    value: 'Could not load "util".',
    file: 'maps.googleapis.com/maps/api/js'
  },
  {
    // https://bentobox.sentry.io/issues/4407724961/?environment=production&project=1513824&referrer=jira_integration
    type: 'TypeError',
    value: 'Illegal invocation',
    file: 'vbpx.js'
  },
  {
    // https://bentobox.sentry.io/issues/3887665657/?project=1513824&referrer=jira_integration
    type: 'ChunkLoadError',
    value: 'Loading chunk',
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/3165737374/events/latest/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value:
      "undefined is not an object (evaluating 'AudioEye.compliance.planRemediations')",
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/3814098233/events/60f7bc0a0a2e44a9a8a950d3913ebdc8/?project=1513824&referrer=latest-event
    type: 'TypeError',
    value: "Cannot set properties of null (setting 'href')",
    file: 'https://directed.extremepizza.com/sitewide_fix.js'
  },
  {
    // https://bentobox.sentry.io/share/issue/53e25d3103e44270af90e5539b8985b3/
    type: 'TypeError',
    value: 'mt is null',
    file: 'audioeye.com'
  },
  {
    // https://bentobox.sentry.io/issues/1127820767/events/90a3711ae80b47a6b7da0b3dfec618b2/
    type: 'SyntaxError',
    value: "Unexpected token ':'",
    file: 'https://app-assets.getbento.com/alerts-component/'
  },
  {
    // https://bentobox.sentry.io/issues/1127820767/events/34ca120f5e7a4fb6b657ec6571c2597b/
    type: 'SyntaxError',
    value: "Unexpected token ':'",
    file: 'store-locator-bundle.js.map'
  },
  {
    // https://bentobox.sentry.io/issues/4981953146/events/cd04f2e691764f22a90290d24a07d2ea/
    type: 'UnhandledRejection',
    value: 'Non-Error promise rejection captured with value: Timeout',
    missingStackTrace: true
  }
];
