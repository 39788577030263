import DOMPurify from 'dompurify';

export function sanitizeUrl(url) {
  try {
    const sanitizedUrl = encodeURI(url);
    // URL components are automatically percent-encoded when using the URL object
    return sanitizedUrl.toString();
  } catch (error) {
    console.error(`Invalid URL: ${url}`);
    return null;
  }
}

export function sanitizeValue(value) {
  if (value === null) {
    return null;
  } else if (typeof value === 'string') {
    return DOMPurify.sanitize(value); // Sanitize HTML content
  }
}
