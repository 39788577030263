import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import buttonTypes from 'constants/buttonTypes';
import reservationServiceTypes from 'constants/reservationServiceTypes';

import LocationNameDisplay from 'components/LocationNameDisplay';
import Button from 'components/Button';
import LinkButton from 'components/LinkButton';
import {
  ButtonSection,
  LocationListItem,
  FlexSection,
  LocationListItemHours
} from 'styles/styledComponents';

import multiButtonProps, {
  DEFAULT_MULTI_BUTTON_PROPS
} from 'constants/multiButtonProps';
import MultiButtonComponent from 'components/MultiButtonComponent';
import { sanitizeValue, sanitizeUrl } from 'utils/sanitize';

export default class LocationListItemComponent extends React.Component {
  getGoogleMapsUrl = () => {
    const { location } = this.props;
    const googleMapsUri = 'https://www.google.com/maps/search/?api=1';
    let searchUrl = '';
    const lat = parseFloat(location.lat);
    const lng = parseFloat(location.lng);
    if (location.google_place_id) {
      searchUrl = `${googleMapsUri}&query=${lat},${lng}&query_place_id=${sanitizeUrl(
        location.google_place_id
      )}`;
    } else {
      const urlSafeAddress = encodeURI(location.address);
      searchUrl = `${googleMapsUri}&query=${urlSafeAddress}`;
    }
    return searchUrl;
  };

  renderHours = () => {
    const { location } = this.props;
    const sanitizedHours = sanitizeValue(location.hours);
    return { __html: sanitizedHours };
  };

  displayCustomButton = () => {
    const { location } = this.props;
    return (
      location.store_locator_custom_button_text &&
      location.store_locator_custom_button_url
    );
  };

  displayMultiButton = () => {
    const { displayMultiButton, multiButton, chowNowId, location } = this.props;

    const shouldDisplayMultiButton =
      displayMultiButton &&
      multiButton !== undefined &&
      multiButton.button_type !== buttonTypes.NONE;

    const hasIntegrationForReservation =
      location.fields &&
      location.fields.reservations &&
      location.fields.reservations.service !== reservationServiceTypes.NONE;

    const hasRequiredInformation =
      (multiButton.button_type !== buttonTypes.RESERVATIONS ||
        hasIntegrationForReservation) &&
      (multiButton.button_type !== buttonTypes.ONLINE_ORDERING ||
        chowNowId !== null);

    return shouldDisplayMultiButton && hasRequiredInformation;
  };

  render() {
    const {
      displayMoreInfo,
      multiButton,
      location,
      handleNameClick,
      chowNowId
    } = this.props;

    const sanitizedPhoneNumber = sanitizeValue(location.phone_number);
    const sanitizedName = sanitizeValue(location.name);
    const sanitizedUrl = sanitizeUrl(location.url);
    const sanitizedAddress = sanitizeValue(location.address);
    const sanitizedCustomButtonText = sanitizeValue(
      location.store_locator_custom_button_text
    );
    const sanitizedCustomButtonUrl = sanitizeUrl(
      location.store_locator_custom_button_url
    );
    const sanitizedSlug = sanitizeValue(location.slug);

    return (
      <LocationListItem className="locationListItem">
        <LocationNameDisplay name={sanitizedName} url={sanitizedUrl} />
        {location.address && (
          <FlexSection as="span">
            <a href={this.getGoogleMapsUrl()} target="_blank" rel="noreferrer">
              {sanitizedAddress}
            </a>
          </FlexSection>
        )}
        {location.phone_number && (
          <FlexSection as="span">
            <a href={`tel:${sanitizedPhoneNumber}`}>{sanitizedPhoneNumber}</a>
          </FlexSection>
        )}
        {location.hours && (
          <FlexSection>
            <LocationListItemHours
              className="locationListItemHours"
              dangerouslySetInnerHTML={this.renderHours()}
            ></LocationListItemHours>
          </FlexSection>
        )}
        {location.distance && (
          <FlexSection>
            <span className="locationListItem-distance">
              {location.distance} miles
            </span>
          </FlexSection>
        )}
        <ButtonSection>
          <MediaQuery query="(min-width: 768px)">
            <Button
              className="locationListItem-customButton locationListItem-link"
              label="View on Map"
              aria-label={`View ${sanitizedName} on Map`}
              handleClick={() => handleNameClick(location)}
              addRightMargin
              addTopMargin
            />
          </MediaQuery>
          {displayMoreInfo && (
            <LinkButton
              className="locationListItem-customButton locationListItem-link"
              label="Location Details"
              aria-label={`Read more about ${sanitizedName}`}
              href={sanitizedUrl}
              addTopMargin
            />
          )}
        </ButtonSection>
        {this.displayCustomButton() && (
          <LinkButton
            className="locationListItem-customButton locationListItem-link"
            label={sanitizedCustomButtonText}
            addTopMargin
            href={sanitizedCustomButtonUrl}
            isExternal
          />
        )}
        {this.displayMultiButton() && (
          <MultiButtonComponent
            {...multiButton}
            chowNowId={chowNowId}
            locationSlug={sanitizedSlug}
          />
        )}
      </LocationListItem>
    );
  }
}

LocationListItemComponent.propTypes = {
  chowNowId: PropTypes.string,
  displayMoreInfo: PropTypes.bool.isRequired,
  displayMultiButton: PropTypes.bool,
  handleNameClick: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  multiButton: PropTypes.shape(multiButtonProps)
};

LocationListItemComponent.defaultProps = {
  chowNowId: null,
  displayMultiButton: false,
  multiButton: { ...DEFAULT_MULTI_BUTTON_PROPS }
};
