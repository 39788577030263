import React from 'react';
import PropTypes from 'prop-types';

import {
  BUTTON_BRAND_CLASSES,
  BUTTON_BRAND_ALT_COLOR_CLASS
} from 'constants/buttonClasses';

import { StyledButton } from 'styles/styledComponents';

const Button = ({
  addTopMargin,
  addRightMargin,
  altColor,
  className,
  handleClick,
  label,
  ...rest
}) => (
  <StyledButton
    addTopMargin={addTopMargin}
    addRightMargin={addRightMargin}
    className={`${BUTTON_BRAND_CLASSES} ${className} ${
      altColor ? BUTTON_BRAND_ALT_COLOR_CLASS : ''
    }`}
    onClick={handleClick}
    tabIndex="0"
    {...rest}
  >
    {label}
  </StyledButton>
);

Button.propTypes = {
  addTopMargin: PropTypes.bool,
  addRightMargin: PropTypes.bool,
  altColor: PropTypes.bool,
  className: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

Button.defaultProps = {
  addTopMargin: false,
  addRightMargin: false,
  altColor: false,
  className: ''
};

export default Button;
