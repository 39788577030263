import PropTypes from 'prop-types';

export const DEFAULT_MULTI_BUTTON_PROPS = {
  button_alt_colors: false,
  button_type: null,
  label: null,
  url: null,
  url_target: false
};

export default {
  button_alt_colors: PropTypes.bool,
  button_type: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  url_target: PropTypes.bool
};
