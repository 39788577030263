const locationData = [
  {
    name: 'Test Loc',
    slug: 'test-loc',
    url: '/location/test-loc/',
    template: 'boxes/location.html',
    created: '2018-07-09T14:20:58.501273Z',
    updated: '2018-07-09T14:20:58.512736Z',
    categories: [],
    fields: {
      reservations: { service: 'none' },
      display_options: {
        use_gmaps_advanced: false,
        display_related_menus: false
      },
      herobasic: {
        multibutton: { button_type: 'none', button_alt_colors: false },
        background_type: 'none'
      },
      flexiblecontent: { sections: null }
    },
    box_type: 'locations',
    homepage: false,
    image: null,
    images: [],
    hours: '',
    description: '',
    address: '',
    street: '',
    city: '',
    state: '',
    postal_code: '',
    phone_number: null,
    email_address: null,
    reservations: {},
    related: {
      jobs: {
        all: [],
        by_slug: {},
        featured: [],
        not_featured: [],
        by_category_slug: {
          administration: {
            name: 'Administration',
            slug: 'administration',
            url: '/category/jobs/administration/',
            template_name: 'boxes/categories/jobs.html',
            all: [],
            by_slug: {}
          },
          'front-of-house': {
            name: 'Front of House',
            slug: 'front-of-house',
            url: '/category/jobs/front-of-house/',
            template_name: 'boxes/categories/jobs.html',
            all: [],
            by_slug: {}
          },
          uncategorized: {
            all: [],
            by_slug: {},
            name: 'Uncategorized',
            slug: 'uncategorized'
          }
        },
        categories: [
          {
            name: 'Administration',
            slug: 'administration',
            url: '/category/jobs/administration/',
            template_name: 'boxes/categories/jobs.html',
            all: [],
            by_slug: {}
          },
          {
            name: 'Front of House',
            slug: 'front-of-house',
            url: '/category/jobs/front-of-house/',
            template_name: 'boxes/categories/jobs.html',
            all: [],
            by_slug: {}
          },
          {
            all: [],
            by_slug: {},
            name: 'Uncategorized',
            slug: 'uncategorized'
          }
        ]
      },
      galleries: {
        by_slug: {
          food: {
            name: 'Food',
            slug: 'food',
            url: '/gallery/food/',
            template: 'boxes/gallery.html',
            created: '2016-10-06T05:31:04.370612Z',
            updated: '2018-07-09T14:59:48.375908Z',
            featured: true,
            fields: {},
            box_type: 'gallery',
            homepage: false,
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/94019fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90869pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44732food-salad-healthy-lunch-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/34675food-dinner-lemon-rice-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/58785hamburger-food-meal-tasty-47725-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ]
          }
        },
        all: [
          {
            name: 'Food',
            slug: 'food',
            url: '/gallery/food/',
            template: 'boxes/gallery.html',
            created: '2016-10-06T05:31:04.370612Z',
            updated: '2018-07-09T14:59:48.375908Z',
            featured: true,
            fields: {},
            box_type: 'gallery',
            homepage: false,
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/94019fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90869pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44732food-salad-healthy-lunch-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/34675food-dinner-lemon-rice-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/58785hamburger-food-meal-tasty-47725-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ]
          }
        ],
        not_featured: [],
        featured: [
          {
            name: 'Food',
            slug: 'food',
            url: '/gallery/food/',
            template: 'boxes/gallery.html',
            created: '2016-10-06T05:31:04.370612Z',
            updated: '2018-07-09T14:59:48.375908Z',
            featured: true,
            fields: {},
            box_type: 'gallery',
            homepage: false,
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/94019fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90869pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44732food-salad-healthy-lunch-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/34675food-dinner-lemon-rice-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/58785hamburger-food-meal-tasty-47725-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ]
          }
        ]
      },
      venues: { by_slug: {}, all: [], not_featured: [], featured: [] },
      menus: {
        box_index_url: '/menus/',
        all: [],
        not_featured: [],
        featured: [],
        by_slug: {}
      },
      team: { by_slug: {}, all: [], not_featured: [], featured: [] },
      press: { by_slug: {}, all: [], not_featured: [], featured: [] },
      news: { by_slug: {}, all: [], not_featured: [], featured: [] },
      events: { by_slug: {}, all: [], not_featured: [], featured: [] }
    }
  },
  {
    name: 'Sensei',
    slug: 'location-one',
    url: '/location/location-one/',
    template: 'boxes/location.html',
    created: '2016-09-30T16:01:26.371356Z',
    updated: '2018-07-16T23:01:32.567363Z',
    categories: [],
    fields: {
      reservations: {
        resy_venue_id: '924',
        service: 'opentable',
        opentable_id: '112753',
        reserve_id: '2b106799-801a-4a6c-b735-ba3c107a2e55',
        section_meta: { published: true },
        seatme_id: 'pulqueria-new-york',
        resy_api_key: 'lnXmgBz1apcP7Lr5Nr3jXduXKYWF0E7V'
      },
      display_options: {
        use_gmaps_advanced: false,
        display_related_menus: true,
        section_meta: {}
      },
      herobasic: {
        darken: true,
        title: 'Hours \u0026 Location',
        background_type: 'images',
        multibutton: {
          button_type: 'none',
          file_target: true,
          button_alt_colors: false,
          url_target: false
        },
        section_meta: { published: true },
        images: [
          {
            alt_text: 'Very pretty.',
            image_url:
              'https://www.filepicker.io/api/file/wmg4tl6TS6xigLjhfPaY',
            image_path:
              'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/mediausers/custom_fields_galleries/images/wb4249aS2G0rDQ8qlIkw_ALs-PLACE-October-906-FINALprint.jpg?fit=max\u0026w=1800\u0026auto=format,compress',
            id: 'repeaterItemId_ef762376-7084-7e66-ba79-e3d9c70600a2'
          }
        ]
      },
      flexiblecontent: {
        section_meta: {},
        sections: [
          {
            one_column_text: {
              content: '\u003cp\u003erggr\u003c/p\u003e',
              title: 'Test'
            },
            two_column_freeform_thumbnails: {
              multibutton_one: {
                button_type: 'none',
                file_target: true,
                button_alt_colors: false,
                url_target: false
              },
              multibutton_two: {
                button_type: 'none',
                file_target: true,
                button_alt_colors: false,
                url_target: false
              }
            },
            split_overlay: {
              multibutton_right: {
                button_type: 'none',
                file_target: true,
                button_alt_colors: false,
                url_target: false
              },
              multibutton_left: {
                button_type: 'none',
                file_target: true,
                button_alt_colors: false,
                url_target: false
              }
            },
            content_type: 'one_column_text',
            id: 'repeaterItemId_909f72c9-8c54-0233-eb0f-691d3411092c',
            full_overlay: {
              multibutton: {
                button_type: 'none',
                file_target: true,
                button_alt_colors: false,
                url_target: false
              },
              add_overlay: true
            }
          }
        ]
      }
    },
    box_type: 'locations',
    homepage: false,
    image: {
      url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/73409ALs-PLACE-October-906-FINALprint.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
      alt_text: 'Restaurant chairs'
    },
    images: [
      {
        url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/73409ALs-PLACE-October-906-FINALprint.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
        alt_text: 'Restaurant chairs'
      },
      {
        url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
        alt_text: 'alt-test'
      }
    ],
    hours:
      '\u003cp\u003e\u003cstrong\u003eMonday - Friday\u003c/strong\u003e\u003cbr\u003e8am - 9pm\u003c/p\u003e\u003cp\u003e\u003cstrong\u003eWeekend\u003c/strong\u003e\u003cbr\u003e10am - 2am\u003c/p\u003e',
    description: '\u003cp\u003eDescription Section\u003c/p\u003e',
    address: '210 Elizabeth Street, New York, NY 10002',
    street: '210 Elizabeth Street',
    city: 'New York',
    state: 'NY',
    postal_code: '10002',
    phone_number: '212-555-5555',
    email_address: null,
    reservations: {},
    related: {
      jobs: {
        all: [
          {
            name: 'General Manager',
            slug: 'general-manager',
            url: '/jobs/general-manager/',
            template: 'boxes/jobs.html',
            created: '2016-10-06T05:50:34.828581Z',
            updated: '2017-06-01T16:38:40.436468Z',
            categories: [
              {
                name: 'Administration',
                slug: 'administration',
                url: '/category/jobs/administration/',
                template_name: 'boxes/categories/jobs.html'
              }
            ],
            featured: false,
            fields: {
              description:
                '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
            },
            box_type: 'jobs',
            homepage: false
          }
        ],
        by_slug: {
          'general-manager': {
            name: 'General Manager',
            slug: 'general-manager',
            url: '/jobs/general-manager/',
            template: 'boxes/jobs.html',
            created: '2016-10-06T05:50:34.828581Z',
            updated: '2017-06-01T16:38:40.436468Z',
            categories: [
              {
                name: 'Administration',
                slug: 'administration',
                url: '/category/jobs/administration/',
                template_name: 'boxes/categories/jobs.html'
              }
            ],
            featured: false,
            fields: {
              description:
                '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
            },
            box_type: 'jobs',
            homepage: false
          }
        },
        featured: [],
        not_featured: [
          {
            name: 'General Manager',
            slug: 'general-manager',
            url: '/jobs/general-manager/',
            template: 'boxes/jobs.html',
            created: '2016-10-06T05:50:34.828581Z',
            updated: '2017-06-01T16:38:40.436468Z',
            categories: [
              {
                name: 'Administration',
                slug: 'administration',
                url: '/category/jobs/administration/',
                template_name: 'boxes/categories/jobs.html'
              }
            ],
            featured: false,
            fields: {
              description:
                '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
            },
            box_type: 'jobs',
            homepage: false
          }
        ],
        by_category_slug: {
          administration: {
            name: 'Administration',
            slug: 'administration',
            url: '/category/jobs/administration/',
            template_name: 'boxes/categories/jobs.html',
            all: [
              {
                name: 'General Manager',
                slug: 'general-manager',
                url: '/jobs/general-manager/',
                template: 'boxes/jobs.html',
                created: '2016-10-06T05:50:34.828581Z',
                updated: '2017-06-01T16:38:40.436468Z',
                categories: [
                  {
                    name: 'Administration',
                    slug: 'administration',
                    url: '/category/jobs/administration/',
                    template_name: 'boxes/categories/jobs.html'
                  }
                ],
                featured: false,
                fields: {
                  description:
                    '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
                },
                box_type: 'jobs',
                homepage: false
              }
            ],
            by_slug: {
              'general-manager': {
                name: 'General Manager',
                slug: 'general-manager',
                url: '/jobs/general-manager/',
                template: 'boxes/jobs.html',
                created: '2016-10-06T05:50:34.828581Z',
                updated: '2017-06-01T16:38:40.436468Z',
                categories: [
                  {
                    name: 'Administration',
                    slug: 'administration',
                    url: '/category/jobs/administration/',
                    template_name: 'boxes/categories/jobs.html'
                  }
                ],
                featured: false,
                fields: {
                  description:
                    '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
                },
                box_type: 'jobs',
                homepage: false
              }
            }
          },
          'front-of-house': {
            name: 'Front of House',
            slug: 'front-of-house',
            url: '/category/jobs/front-of-house/',
            template_name: 'boxes/categories/jobs.html',
            all: [],
            by_slug: {}
          },
          uncategorized: {
            all: [],
            by_slug: {},
            name: 'Uncategorized',
            slug: 'uncategorized'
          }
        },
        categories: [
          {
            name: 'Administration',
            slug: 'administration',
            url: '/category/jobs/administration/',
            template_name: 'boxes/categories/jobs.html',
            all: [
              {
                name: 'General Manager',
                slug: 'general-manager',
                url: '/jobs/general-manager/',
                template: 'boxes/jobs.html',
                created: '2016-10-06T05:50:34.828581Z',
                updated: '2017-06-01T16:38:40.436468Z',
                categories: [
                  {
                    name: 'Administration',
                    slug: 'administration',
                    url: '/category/jobs/administration/',
                    template_name: 'boxes/categories/jobs.html'
                  }
                ],
                featured: false,
                fields: {
                  description:
                    '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
                },
                box_type: 'jobs',
                homepage: false
              }
            ],
            by_slug: {
              'general-manager': {
                name: 'General Manager',
                slug: 'general-manager',
                url: '/jobs/general-manager/',
                template: 'boxes/jobs.html',
                created: '2016-10-06T05:50:34.828581Z',
                updated: '2017-06-01T16:38:40.436468Z',
                categories: [
                  {
                    name: 'Administration',
                    slug: 'administration',
                    url: '/category/jobs/administration/',
                    template_name: 'boxes/categories/jobs.html'
                  }
                ],
                featured: false,
                fields: {
                  description:
                    '\u003cp\u003eAccountant. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e'
                },
                box_type: 'jobs',
                homepage: false
              }
            }
          },
          {
            name: 'Front of House',
            slug: 'front-of-house',
            url: '/category/jobs/front-of-house/',
            template_name: 'boxes/categories/jobs.html',
            all: [],
            by_slug: {}
          },
          {
            all: [],
            by_slug: {},
            name: 'Uncategorized',
            slug: 'uncategorized'
          }
        ]
      },
      galleries: {
        by_slug: {
          food: {
            name: 'Food',
            slug: 'food',
            url: '/gallery/food/',
            template: 'boxes/gallery.html',
            created: '2016-10-06T05:31:04.370612Z',
            updated: '2018-07-09T14:59:48.375908Z',
            featured: true,
            fields: {},
            box_type: 'gallery',
            homepage: false,
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/94019fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90869pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44732food-salad-healthy-lunch-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/34675food-dinner-lemon-rice-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/58785hamburger-food-meal-tasty-47725-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ]
          }
        },
        all: [
          {
            name: 'Food',
            slug: 'food',
            url: '/gallery/food/',
            template: 'boxes/gallery.html',
            created: '2016-10-06T05:31:04.370612Z',
            updated: '2018-07-09T14:59:48.375908Z',
            featured: true,
            fields: {},
            box_type: 'gallery',
            homepage: false,
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/94019fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90869pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44732food-salad-healthy-lunch-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/34675food-dinner-lemon-rice-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/58785hamburger-food-meal-tasty-47725-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ]
          }
        ],
        not_featured: [],
        featured: [
          {
            name: 'Food',
            slug: 'food',
            url: '/gallery/food/',
            template: 'boxes/gallery.html',
            created: '2016-10-06T05:31:04.370612Z',
            updated: '2018-07-09T14:59:48.375908Z',
            featured: true,
            fields: {},
            box_type: 'gallery',
            homepage: false,
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/94019fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90869pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44732food-salad-healthy-lunch-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/34675food-dinner-lemon-rice-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/58785hamburger-food-meal-tasty-47725-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ]
          }
        ]
      },
      venues: {
        by_slug: {
          venue: {
            name: 'Venue Two',
            slug: 'venue',
            url: '/private-events-venue/venue/',
            template: 'boxes/private_event_venue.html',
            created: '2016-10-06T05:18:18.110623Z',
            updated: '2018-06-05T19:21:28.584426Z',
            featured: true,
            fields: {},
            box_type: 'venue',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/614728568gallery2.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/614728568gallery2.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/83638fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90967pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/68922restaurant-alcohol-bar-drinks-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            description:
              '\u003cp\u003eVenue Two. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e',
            capacity: '12'
          }
        },
        all: [
          {
            name: 'Venue Two',
            slug: 'venue',
            url: '/private-events-venue/venue/',
            template: 'boxes/private_event_venue.html',
            created: '2016-10-06T05:18:18.110623Z',
            updated: '2018-06-05T19:21:28.584426Z',
            featured: true,
            fields: {},
            box_type: 'venue',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/614728568gallery2.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/614728568gallery2.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/83638fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90967pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/68922restaurant-alcohol-bar-drinks-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            description:
              '\u003cp\u003eVenue Two. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e',
            capacity: '12'
          }
        ],
        not_featured: [],
        featured: [
          {
            name: 'Venue Two',
            slug: 'venue',
            url: '/private-events-venue/venue/',
            template: 'boxes/private_event_venue.html',
            created: '2016-10-06T05:18:18.110623Z',
            updated: '2018-06-05T19:21:28.584426Z',
            featured: true,
            fields: {},
            box_type: 'venue',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/614728568gallery2.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/614728568gallery2.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/83638fried-eggs-breakfast-toast-food-50600-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/90967pexels-photo-62097-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/68922restaurant-alcohol-bar-drinks-large.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            description:
              '\u003cp\u003eVenue Two. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e',
            capacity: '12'
          }
        ]
      },
      menus: {
        box_index_url: '/menus/',
        all: [
          {
            name: 'Lunch (Copy) (Copy)',
            slug: 'lunch-copy-copy',
            url: '/menu/lunch-copy-copy/',
            template: 'boxes/menu.html',
            created: '2018-01-31T21:52:32.286452Z',
            updated: '2018-06-18T19:52:57.272804Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: null,
            images: [],
            sections: [
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Entrees',
                description: null,
                menu_items: [
                  {
                    name: 'Seared Steak',
                    description: null,
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '65.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Grilled Chicken',
                    description: null,
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '45.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              }
            ],
            description: '\u003cp\u003e\u0026nbsp;\u003c/p\u003e',
            file: null,
            layout: {
              blocks: {
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    },
                    {
                      name: 'Entrees',
                      description: null,
                      menu_items: [
                        {
                          name: 'Seared Steak',
                          description: null,
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '65.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Grilled Chicken',
                          description: null,
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '45.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                },
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                }
              },
              slug: 'two_columns'
            }
          },
          {
            name: 'Lunch (Copy)',
            slug: 'lunch-copy',
            url: '/menu/lunch-copy/',
            template: 'boxes/menu.html',
            created: '2018-01-31T21:48:36.841096Z',
            updated: '2018-06-18T19:52:57.285614Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: 'alt-test'
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: 'alt-test'
              }
            ],
            sections: [
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              }
            ],
            description: '',
            file: null,
            layout: {
              blocks: {
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                },
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                }
              },
              slug: 'two_columns'
            }
          },
          {
            name: 'Lunch',
            slug: 'lunch',
            url: '/menu/lunch/',
            template: 'boxes/menu.html',
            created: '2016-10-06T04:43:14.299796Z',
            updated: '2018-06-18T19:52:57.298056Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44980pexels-photo-62097-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44980pexels-photo-62097-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/11239fried-eggs-breakfast-toast-food-50600-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            sections: [
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast yes yes',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: '101.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: '102.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: '104.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Test Section',
                description: 'Test Descriptions',
                menu_items: [
                  {
                    name: 'Test Item',
                    description: 'Test',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: []
                  },
                  {
                    name: 'Test Item',
                    description: 'Test',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: []
                  }
                ],
                is_itemized: true
              }
            ],
            description: '',
            file: null,
            layout: {
              blocks: {
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    },
                    {
                      name: 'Test Section',
                      description: 'Test Descriptions',
                      menu_items: [
                        {
                          name: 'Test Item',
                          description: 'Test',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: []
                        },
                        {
                          name: 'Test Item',
                          description: 'Test',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: []
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                },
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast yes yes',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: '101.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: '102.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: '104.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                }
              },
              slug: 'two_columns'
            }
          }
        ],
        not_featured: [
          {
            name: 'Lunch (Copy) (Copy)',
            slug: 'lunch-copy-copy',
            url: '/menu/lunch-copy-copy/',
            template: 'boxes/menu.html',
            created: '2018-01-31T21:52:32.286452Z',
            updated: '2018-06-18T19:52:57.272804Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: null,
            images: [],
            sections: [
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Entrees',
                description: null,
                menu_items: [
                  {
                    name: 'Seared Steak',
                    description: null,
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '65.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Grilled Chicken',
                    description: null,
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '45.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              }
            ],
            description: '\u003cp\u003e\u0026nbsp;\u003c/p\u003e',
            file: null,
            layout: {
              blocks: {
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    },
                    {
                      name: 'Entrees',
                      description: null,
                      menu_items: [
                        {
                          name: 'Seared Steak',
                          description: null,
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '65.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Grilled Chicken',
                          description: null,
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '45.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                },
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                }
              },
              slug: 'two_columns'
            }
          },
          {
            name: 'Lunch (Copy)',
            slug: 'lunch-copy',
            url: '/menu/lunch-copy/',
            template: 'boxes/menu.html',
            created: '2018-01-31T21:48:36.841096Z',
            updated: '2018-06-18T19:52:57.285614Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: 'alt-test'
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: 'alt-test'
              }
            ],
            sections: [
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              }
            ],
            description: '',
            file: null,
            layout: {
              blocks: {
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                },
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                }
              },
              slug: 'two_columns'
            }
          },
          {
            name: 'Lunch',
            slug: 'lunch',
            url: '/menu/lunch/',
            template: 'boxes/menu.html',
            created: '2016-10-06T04:43:14.299796Z',
            updated: '2018-06-18T19:52:57.298056Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44980pexels-photo-62097-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44980pexels-photo-62097-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/11239fried-eggs-breakfast-toast-food-50600-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            sections: [
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast yes yes',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: '101.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: '102.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: '104.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Test Section',
                description: 'Test Descriptions',
                menu_items: [
                  {
                    name: 'Test Item',
                    description: 'Test',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: []
                  },
                  {
                    name: 'Test Item',
                    description: 'Test',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: []
                  }
                ],
                is_itemized: true
              }
            ],
            description: '',
            file: null,
            layout: {
              blocks: {
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    },
                    {
                      name: 'Test Section',
                      description: 'Test Descriptions',
                      menu_items: [
                        {
                          name: 'Test Item',
                          description: 'Test',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: []
                        },
                        {
                          name: 'Test Item',
                          description: 'Test',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: []
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                },
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast yes yes',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: '101.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: '102.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: '104.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                }
              },
              slug: 'two_columns'
            }
          }
        ],
        featured: [],
        by_slug: {
          lunch: {
            name: 'Lunch',
            slug: 'lunch',
            url: '/menu/lunch/',
            template: 'boxes/menu.html',
            created: '2016-10-06T04:43:14.299796Z',
            updated: '2018-06-18T19:52:57.298056Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44980pexels-photo-62097-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/44980pexels-photo-62097-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              },
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/11239fried-eggs-breakfast-toast-food-50600-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            sections: [
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast yes yes',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: '101.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: '102.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: '104.00',
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Test Section',
                description: 'Test Descriptions',
                menu_items: [
                  {
                    name: 'Test Item',
                    description: 'Test',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: []
                  },
                  {
                    name: 'Test Item',
                    description: 'Test',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: []
                  }
                ],
                is_itemized: true
              }
            ],
            description: '',
            file: null,
            layout: {
              blocks: {
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    },
                    {
                      name: 'Test Section',
                      description: 'Test Descriptions',
                      menu_items: [
                        {
                          name: 'Test Item',
                          description: 'Test',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: []
                        },
                        {
                          name: 'Test Item',
                          description: 'Test',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: []
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                },
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast yes yes',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: '101.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: '102.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: '104.00',
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                }
              },
              slug: 'two_columns'
            }
          },
          'lunch-copy-copy': {
            name: 'Lunch (Copy) (Copy)',
            slug: 'lunch-copy-copy',
            url: '/menu/lunch-copy-copy/',
            template: 'boxes/menu.html',
            created: '2018-01-31T21:52:32.286452Z',
            updated: '2018-06-18T19:52:57.272804Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: null,
            images: [],
            sections: [
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Entrees',
                description: null,
                menu_items: [
                  {
                    name: 'Seared Steak',
                    description: null,
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '65.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Grilled Chicken',
                    description: null,
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '45.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              }
            ],
            description: '\u003cp\u003e\u0026nbsp;\u003c/p\u003e',
            file: null,
            layout: {
              blocks: {
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    },
                    {
                      name: 'Entrees',
                      description: null,
                      menu_items: [
                        {
                          name: 'Seared Steak',
                          description: null,
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '65.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Grilled Chicken',
                          description: null,
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '45.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                },
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                }
              },
              slug: 'two_columns'
            }
          },
          'lunch-copy': {
            name: 'Lunch (Copy)',
            slug: 'lunch-copy',
            url: '/menu/lunch-copy/',
            template: 'boxes/menu.html',
            created: '2018-01-31T21:48:36.841096Z',
            updated: '2018-06-18T19:52:57.285614Z',
            featured: false,
            fields: {},
            box_type: 'menus',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: 'alt-test'
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/66568IMG_1649.jpg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: 'alt-test'
              }
            ],
            sections: [
              {
                name: 't',
                description: 't',
                menu_items: [
                  {
                    name: 't',
                    description: 't',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '1.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'From the Griddle',
                description: '',
                menu_items: [
                  {
                    name: 'French Toast',
                    description:
                      'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              },
              {
                name: 'Toast',
                description: 'our exclusive toast selection (off menu)',
                menu_items: [
                  {
                    name: 'Cinnamon Toast',
                    description: 'homemade butter with freshly ground cinnamon',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [],
                    prices: [
                      {
                        label: null,
                        price: '14.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  },
                  {
                    name: 'Avocado Toast',
                    description:
                      'avocados smashed with garlic + truffle oil on toast',
                    image: null,
                    dietary_types: [],
                    spicy_rating: '',
                    allergens: [],
                    addons: [
                      { name: 'Add an Egg', price: '6.00', price_max: null }
                    ],
                    prices: [
                      {
                        label: 'regular',
                        price: '15.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'with an egg',
                        price: '22.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      },
                      {
                        label: 'bb',
                        price: '12.00',
                        price_max: null,
                        calories: null,
                        calories_max: null,
                        unit: null
                      }
                    ]
                  }
                ],
                is_itemized: true
              }
            ],
            description: '',
            file: null,
            layout: {
              blocks: {
                column_one: {
                  sections: [
                    {
                      name: 'Toast',
                      description: 'our exclusive toast selection (off menu)',
                      menu_items: [
                        {
                          name: 'Cinnamon Toast',
                          description:
                            'homemade butter with freshly ground cinnamon',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '14.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        },
                        {
                          name: 'Avocado Toast',
                          description:
                            'avocados smashed with garlic + truffle oil on toast',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [
                            {
                              name: 'Add an Egg',
                              price: '6.00',
                              price_max: null
                            }
                          ],
                          prices: [
                            {
                              label: 'regular',
                              price: '15.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'with an egg',
                              price: '22.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            },
                            {
                              label: 'bb',
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_one'
                },
                column_two: {
                  sections: [
                    {
                      name: 'From the Griddle',
                      description: '',
                      menu_items: [
                        {
                          name: 'French Toast',
                          description:
                            'brioche, vanilla cr\u00e8me br\u00fbl\u00e9e batter, maple syrup',
                          image: null,
                          dietary_types: [],
                          spicy_rating: '',
                          allergens: [],
                          addons: [],
                          prices: [
                            {
                              label: null,
                              price: '12.00',
                              price_max: null,
                              calories: null,
                              calories_max: null,
                              unit: null
                            }
                          ]
                        }
                      ],
                      is_itemized: true
                    }
                  ],
                  slug: 'column_two'
                }
              },
              slug: 'two_columns'
            }
          }
        }
      },
      team: {
        by_slug: {
          'john-smith': {
            name: 'John Smith',
            slug: 'john-smith',
            url: '/team-member/john-smith/',
            template: 'boxes/team_member.html',
            created: '2016-10-05T14:13:59.131833Z',
            updated: '2018-06-07T18:26:29.056443Z',
            featured: false,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Head Chef',
            bio: '\u003cp\u003eNo, not that John Smith. The other one. The one from Scranton.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/93451chef_handsjpg.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            social: {
              twitter: null,
              facebook: null,
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: 'http://youtube.com',
              pinterest: null
            }
          },
          'john-doe': {
            name: 'John Doe',
            slug: 'john-doe',
            url: '/team-member/john-doe/',
            template: 'boxes/team_member.html',
            created: '2017-03-22T21:01:36.224246Z',
            updated: '2018-06-12T19:17:30.868743Z',
            featured: true,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Silent Partner',
            bio: '',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/31142silent_partner.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: 'Nice stock photo.'
            },
            social: {
              twitter: null,
              facebook: 'www.facebook.com',
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: 'www.youtube.com',
              pinterest: null
            }
          },
          'alice-cooper': {
            name: 'Alice Cooper',
            slug: 'alice-cooper',
            url: '/team-member/alice-cooper/',
            template: 'boxes/team_member.html',
            created: '2016-10-05T14:13:18.331891Z',
            updated: '2018-06-12T19:17:30.880174Z',
            featured: false,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Front of House Manager',
            bio: '\u003cp\u003eYes, from Alice In Chains. No, wait. That\u0026#39;s not right.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/67230front_of_house.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            social: {
              twitter: null,
              facebook: 'http://facebook.com',
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: null,
              pinterest: null
            }
          }
        },
        all: [
          {
            name: 'John Smith',
            slug: 'john-smith',
            url: '/team-member/john-smith/',
            template: 'boxes/team_member.html',
            created: '2016-10-05T14:13:59.131833Z',
            updated: '2018-06-07T18:26:29.056443Z',
            featured: false,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Head Chef',
            bio: '\u003cp\u003eNo, not that John Smith. The other one. The one from Scranton.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/93451chef_handsjpg.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            social: {
              twitter: null,
              facebook: null,
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: 'http://youtube.com',
              pinterest: null
            }
          },
          {
            name: 'John Doe',
            slug: 'john-doe',
            url: '/team-member/john-doe/',
            template: 'boxes/team_member.html',
            created: '2017-03-22T21:01:36.224246Z',
            updated: '2018-06-12T19:17:30.868743Z',
            featured: true,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Silent Partner',
            bio: '',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/31142silent_partner.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: 'Nice stock photo.'
            },
            social: {
              twitter: null,
              facebook: 'www.facebook.com',
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: 'www.youtube.com',
              pinterest: null
            }
          },
          {
            name: 'Alice Cooper',
            slug: 'alice-cooper',
            url: '/team-member/alice-cooper/',
            template: 'boxes/team_member.html',
            created: '2016-10-05T14:13:18.331891Z',
            updated: '2018-06-12T19:17:30.880174Z',
            featured: false,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Front of House Manager',
            bio: '\u003cp\u003eYes, from Alice In Chains. No, wait. That\u0026#39;s not right.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/67230front_of_house.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            social: {
              twitter: null,
              facebook: 'http://facebook.com',
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: null,
              pinterest: null
            }
          }
        ],
        not_featured: [
          {
            name: 'John Smith',
            slug: 'john-smith',
            url: '/team-member/john-smith/',
            template: 'boxes/team_member.html',
            created: '2016-10-05T14:13:59.131833Z',
            updated: '2018-06-07T18:26:29.056443Z',
            featured: false,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Head Chef',
            bio: '\u003cp\u003eNo, not that John Smith. The other one. The one from Scranton.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/93451chef_handsjpg.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            social: {
              twitter: null,
              facebook: null,
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: 'http://youtube.com',
              pinterest: null
            }
          },
          {
            name: 'Alice Cooper',
            slug: 'alice-cooper',
            url: '/team-member/alice-cooper/',
            template: 'boxes/team_member.html',
            created: '2016-10-05T14:13:18.331891Z',
            updated: '2018-06-12T19:17:30.880174Z',
            featured: false,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Front of House Manager',
            bio: '\u003cp\u003eYes, from Alice In Chains. No, wait. That\u0026#39;s not right.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/67230front_of_house.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            social: {
              twitter: null,
              facebook: 'http://facebook.com',
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: null,
              pinterest: null
            }
          }
        ],
        featured: [
          {
            name: 'John Doe',
            slug: 'john-doe',
            url: '/team-member/john-doe/',
            template: 'boxes/team_member.html',
            created: '2017-03-22T21:01:36.224246Z',
            updated: '2018-06-12T19:17:30.868743Z',
            featured: true,
            fields: {},
            box_type: 'team',
            homepage: false,
            title: 'Silent Partner',
            bio: '',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/31142silent_partner.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: 'Nice stock photo.'
            },
            social: {
              twitter: null,
              facebook: 'www.facebook.com',
              linkedin: null,
              instagram: null,
              google_plus: null,
              youtube: 'www.youtube.com',
              pinterest: null
            }
          }
        ]
      },
      press: {
        by_slug: {
          'nix-is-on-trend-but-all-over-the-map': {
            name: 'Nix Is On-Trend, But All Over the Map',
            slug: 'nix-is-on-trend-but-all-over-the-map',
            url: '/press-item/nix-is-on-trend-but-all-over-the-map/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:58:30.829772Z',
            updated: '2017-01-04T14:28:02.662439Z',
            featured: true,
            fields: {},
            box_type: 'press',
            homepage: false,
            title: 'Nix Is On-Trend, But All Over the Map',
            date: '2016-07-06',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail698.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater NY',
            file: null,
            external_url:
              'http://ny.eater.com/2016/7/6/12106594/nyc-nix-review-vegetarian-john-fraser'
          },
          'the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year':
            {
              name: 'The 12 Most Utterly Gorgeous Plates of Food Bill Addison Ate This Year \u003ci class="fa fa-video-camera" aria-hidden="true"\u003e\u003c/i\u003e',
              slug: 'the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year',
              url: '/press-item/the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year/',
              template: 'boxes/press.html',
              created: '2016-10-06T04:57:21.198810Z',
              updated: '2017-03-02T19:03:15.728565Z',
              featured: true,
              fields: {},
              box_type: 'press',
              homepage: false,
              title:
                'The 12 Most Utterly Gorgeous Plates of Food Bill Addison Ate This Year \u003ci class="fa fa-video-camera" aria-hidden="true"\u003e\u003c/i\u003e',
              date: '2016-07-14',
              image: {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail39870.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
                alt_text: ''
              },
              source: 'Eater',
              file: 'https://media-cdn.getbento.com/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/press_clippings/rb06dZ2IQdWaTxblCgRo_about_hero.jpg',
              external_url:
                'http://www.eater.com/2016/7/14/12192824/best-food-photos-usa'
            },
          'del-posto-is-americas-high-church-of-pasta': {
            name: 'Del Posto Is \u200bAmerica\u0027s\u200b High Church of Pasta',
            slug: 'del-posto-is-americas-high-church-of-pasta',
            url: '/press-item/del-posto-is-americas-high-church-of-pasta/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:58:03.274239Z',
            updated: '2017-01-04T14:27:53.828014Z',
            featured: false,
            fields: {},
            box_type: 'press',
            homepage: false,
            title:
              'Del Posto Is \u200bAmerica\u0027s\u200b High Church of Pasta',
            date: '2016-07-07',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail28380.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater',
            file: null,
            external_url:
              'http://www.eater.com/2016/7/7/12113502/del-posto-new-york-best-pasta'
          }
        },
        all: [
          {
            name: 'The 12 Most Utterly Gorgeous Plates of Food Bill Addison Ate This Year \u003ci class="fa fa-video-camera" aria-hidden="true"\u003e\u003c/i\u003e',
            slug: 'the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year',
            url: '/press-item/the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:57:21.198810Z',
            updated: '2017-03-02T19:03:15.728565Z',
            featured: true,
            fields: {},
            box_type: 'press',
            homepage: false,
            title:
              'The 12 Most Utterly Gorgeous Plates of Food Bill Addison Ate This Year \u003ci class="fa fa-video-camera" aria-hidden="true"\u003e\u003c/i\u003e',
            date: '2016-07-14',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail39870.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater',
            file: 'https://media-cdn.getbento.com/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/press_clippings/rb06dZ2IQdWaTxblCgRo_about_hero.jpg',
            external_url:
              'http://www.eater.com/2016/7/14/12192824/best-food-photos-usa'
          },
          {
            name: 'Del Posto Is \u200bAmerica\u0027s\u200b High Church of Pasta',
            slug: 'del-posto-is-americas-high-church-of-pasta',
            url: '/press-item/del-posto-is-americas-high-church-of-pasta/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:58:03.274239Z',
            updated: '2017-01-04T14:27:53.828014Z',
            featured: false,
            fields: {},
            box_type: 'press',
            homepage: false,
            title:
              'Del Posto Is \u200bAmerica\u0027s\u200b High Church of Pasta',
            date: '2016-07-07',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail28380.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater',
            file: null,
            external_url:
              'http://www.eater.com/2016/7/7/12113502/del-posto-new-york-best-pasta'
          },
          {
            name: 'Nix Is On-Trend, But All Over the Map',
            slug: 'nix-is-on-trend-but-all-over-the-map',
            url: '/press-item/nix-is-on-trend-but-all-over-the-map/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:58:30.829772Z',
            updated: '2017-01-04T14:28:02.662439Z',
            featured: true,
            fields: {},
            box_type: 'press',
            homepage: false,
            title: 'Nix Is On-Trend, But All Over the Map',
            date: '2016-07-06',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail698.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater NY',
            file: null,
            external_url:
              'http://ny.eater.com/2016/7/6/12106594/nyc-nix-review-vegetarian-john-fraser'
          }
        ],
        not_featured: [
          {
            name: 'Del Posto Is \u200bAmerica\u0027s\u200b High Church of Pasta',
            slug: 'del-posto-is-americas-high-church-of-pasta',
            url: '/press-item/del-posto-is-americas-high-church-of-pasta/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:58:03.274239Z',
            updated: '2017-01-04T14:27:53.828014Z',
            featured: false,
            fields: {},
            box_type: 'press',
            homepage: false,
            title:
              'Del Posto Is \u200bAmerica\u0027s\u200b High Church of Pasta',
            date: '2016-07-07',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail28380.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater',
            file: null,
            external_url:
              'http://www.eater.com/2016/7/7/12113502/del-posto-new-york-best-pasta'
          }
        ],
        featured: [
          {
            name: 'The 12 Most Utterly Gorgeous Plates of Food Bill Addison Ate This Year \u003ci class="fa fa-video-camera" aria-hidden="true"\u003e\u003c/i\u003e',
            slug: 'the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year',
            url: '/press-item/the-12-most-utterly-gorgeous-plates-of-food-bill-addison-ate-this-year/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:57:21.198810Z',
            updated: '2017-03-02T19:03:15.728565Z',
            featured: true,
            fields: {},
            box_type: 'press',
            homepage: false,
            title:
              'The 12 Most Utterly Gorgeous Plates of Food Bill Addison Ate This Year \u003ci class="fa fa-video-camera" aria-hidden="true"\u003e\u003c/i\u003e',
            date: '2016-07-14',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail39870.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater',
            file: 'https://media-cdn.getbento.com/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/press_clippings/rb06dZ2IQdWaTxblCgRo_about_hero.jpg',
            external_url:
              'http://www.eater.com/2016/7/14/12192824/best-food-photos-usa'
          },
          {
            name: 'Nix Is On-Trend, But All Over the Map',
            slug: 'nix-is-on-trend-but-all-over-the-map',
            url: '/press-item/nix-is-on-trend-but-all-over-the-map/',
            template: 'boxes/press.html',
            created: '2016-10-06T04:58:30.829772Z',
            updated: '2017-01-04T14:28:02.662439Z',
            featured: true,
            fields: {},
            box_type: 'press',
            homepage: false,
            title: 'Nix Is On-Trend, But All Over the Map',
            date: '2016-07-06',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/press_thumbnail698.jpeg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            },
            source: 'Eater NY',
            file: null,
            external_url:
              'http://ny.eater.com/2016/7/6/12106594/nyc-nix-review-vegetarian-john-fraser'
          }
        ]
      },
      news: {
        by_slug: {
          'news-article-2': {
            name: 'News Article 2',
            slug: 'news-article-2',
            url: '/news-item/news-article-2/',
            template: 'boxes/news_item.html',
            created: '2017-01-04T15:41:28.935086Z',
            updated: '2017-01-23T21:13:24.534216Z',
            featured: false,
            fields: {},
            box_type: 'blog',
            homepage: false,
            title: 'News Article 2',
            date: '2017-01-04T15:41:28.935086Z',
            content:
              '\u003cp\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus libero felis, scelerisque non sollicitudin non, sagittis nec diam. Curabitur nec nisi sed magna viverra vulputate at non mi. In in turpis commodo, ultrices arcu et, placerat est. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc placerat, tortor quis malesuada tincidunt, purus ex tincidunt tortor, eu tristique nunc elit a enim. Quisque auctor scelerisque neque et tempus. Nulla eu ex eget erat laoreet ultricies id quis dui.\u003c/p\u003e\u003cp\u003eCras libero odio, lacinia ac lectus non, tristique tempus elit. Quisque venenatis purus justo, id commodo ex ullamcorper non. Integer imperdiet, tortor congue maximus semper, leo diam fermentum neque, ac semper metus purus vel dolor. In quis leo et turpis vestibulum aliquet hendrerit id turpis. Maecenas ac lectus ante. Fusce et ante ac mauris semper dictum. Suspendisse sed nibh quis lacus aliquet congue nec et ligula. Integer ut arcu libero.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/83695privatevents_hero1.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            }
          }
        },
        all: [
          {
            name: 'News Article 2',
            slug: 'news-article-2',
            url: '/news-item/news-article-2/',
            template: 'boxes/news_item.html',
            created: '2017-01-04T15:41:28.935086Z',
            updated: '2017-01-23T21:13:24.534216Z',
            featured: false,
            fields: {},
            box_type: 'blog',
            homepage: false,
            title: 'News Article 2',
            date: '2017-01-04T15:41:28.935086Z',
            content:
              '\u003cp\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus libero felis, scelerisque non sollicitudin non, sagittis nec diam. Curabitur nec nisi sed magna viverra vulputate at non mi. In in turpis commodo, ultrices arcu et, placerat est. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc placerat, tortor quis malesuada tincidunt, purus ex tincidunt tortor, eu tristique nunc elit a enim. Quisque auctor scelerisque neque et tempus. Nulla eu ex eget erat laoreet ultricies id quis dui.\u003c/p\u003e\u003cp\u003eCras libero odio, lacinia ac lectus non, tristique tempus elit. Quisque venenatis purus justo, id commodo ex ullamcorper non. Integer imperdiet, tortor congue maximus semper, leo diam fermentum neque, ac semper metus purus vel dolor. In quis leo et turpis vestibulum aliquet hendrerit id turpis. Maecenas ac lectus ante. Fusce et ante ac mauris semper dictum. Suspendisse sed nibh quis lacus aliquet congue nec et ligula. Integer ut arcu libero.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/83695privatevents_hero1.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            }
          }
        ],
        not_featured: [
          {
            name: 'News Article 2',
            slug: 'news-article-2',
            url: '/news-item/news-article-2/',
            template: 'boxes/news_item.html',
            created: '2017-01-04T15:41:28.935086Z',
            updated: '2017-01-23T21:13:24.534216Z',
            featured: false,
            fields: {},
            box_type: 'blog',
            homepage: false,
            title: 'News Article 2',
            date: '2017-01-04T15:41:28.935086Z',
            content:
              '\u003cp\u003eLorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus libero felis, scelerisque non sollicitudin non, sagittis nec diam. Curabitur nec nisi sed magna viverra vulputate at non mi. In in turpis commodo, ultrices arcu et, placerat est. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc placerat, tortor quis malesuada tincidunt, purus ex tincidunt tortor, eu tristique nunc elit a enim. Quisque auctor scelerisque neque et tempus. Nulla eu ex eget erat laoreet ultricies id quis dui.\u003c/p\u003e\u003cp\u003eCras libero odio, lacinia ac lectus non, tristique tempus elit. Quisque venenatis purus justo, id commodo ex ullamcorper non. Integer imperdiet, tortor congue maximus semper, leo diam fermentum neque, ac semper metus purus vel dolor. In quis leo et turpis vestibulum aliquet hendrerit id turpis. Maecenas ac lectus ante. Fusce et ante ac mauris semper dictum. Suspendisse sed nibh quis lacus aliquet congue nec et ligula. Integer ut arcu libero.\u003c/p\u003e',
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/83695privatevents_hero1.jpg?w=600\u0026fit=max\u0026auto=compress,format\u0026h=600',
              alt_text: ''
            }
          }
        ],
        featured: []
      },
      events: {
        by_slug: {
          'my-second-event': {
            name: 'My Second Event',
            slug: 'my-second-event',
            url: '/event/my-second-event/',
            template: 'boxes/event.html',
            created: '2016-10-06T05:37:46.072866Z',
            updated: '2018-01-31T21:56:06.706418Z',
            featured: true,
            fields: { external_link_button_label: null },
            box_type: 'events',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/45147pexels-photo-66640-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/45147pexels-photo-66640-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            description:
              '\u003cp\u003eLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e',
            starts: '',
            ends: '',
            external_url: '',
            starts_month: null,
            starts_year: null,
            ends_month: null,
            ends_year: null,
            occurred: null,
            has_start_date: false,
            has_end_date: false,
            has_start_time: false,
            has_end_time: false
          }
        },
        all: [
          {
            name: 'My Second Event',
            slug: 'my-second-event',
            url: '/event/my-second-event/',
            template: 'boxes/event.html',
            created: '2016-10-06T05:37:46.072866Z',
            updated: '2018-01-31T21:56:06.706418Z',
            featured: true,
            fields: { external_link_button_label: null },
            box_type: 'events',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/45147pexels-photo-66640-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/45147pexels-photo-66640-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            description:
              '\u003cp\u003eLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e',
            starts: '',
            ends: '',
            external_url: '',
            starts_month: null,
            starts_year: null,
            ends_month: null,
            ends_year: null,
            occurred: null,
            has_start_date: false,
            has_end_date: false,
            has_start_time: false,
            has_end_time: false
          }
        ],
        not_featured: [],
        featured: [
          {
            name: 'My Second Event',
            slug: 'my-second-event',
            url: '/event/my-second-event/',
            template: 'boxes/event.html',
            created: '2016-10-06T05:37:46.072866Z',
            updated: '2018-01-31T21:56:06.706418Z',
            featured: true,
            fields: { external_link_button_label: null },
            box_type: 'events',
            homepage: false,
            image: {
              url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/45147pexels-photo-66640-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
              alt_text: ''
            },
            images: [
              {
                url: 'https://getbento.imgix.net/accounts/35ddb2b117d65b4fe622bf79c1d51975/media/images/45147pexels-photo-66640-large.jpeg?w=1800\u0026fit=max\u0026auto=compress,format\u0026h=1800',
                alt_text: ''
              }
            ],
            description:
              '\u003cp\u003eLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\u003c/p\u003e',
            starts: '',
            ends: '',
            external_url: '',
            starts_month: null,
            starts_year: null,
            ends_month: null,
            ends_year: null,
            occurred: null,
            has_start_date: false,
            has_end_date: false,
            has_start_time: false,
            has_end_time: false
          }
        ]
      }
    }
  }
];
export default locationData;
