import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .gm-style {
    font: inherit !important;
  }
  .gm-style-iw {
    text-align: center !important;
  }
  .gm-style-iw-d {
    overflow: hidden !important;
  } 
  .gm-ui-hover-effect {
    filter: grayscale(1) brightness(0) invert(1);
  }
`;

export default GlobalStyle;
