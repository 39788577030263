import React from 'react';
import PropTypes from 'prop-types';

import buttonTypes from 'constants/buttonTypes';
import multiButtonProps, {
  DEFAULT_MULTI_BUTTON_PROPS
} from 'constants/multiButtonProps';

import { openReservationsModal } from 'utils/reservationsModal';
import throttle from 'utils/throttle';

import Button from 'components/Button';
import LinkButton from 'components/LinkButton';

const MultiButtonComponent = props => {
  const {
    label,
    url,
    url_target,
    button_type,
    button_alt_colors,
    chowNowId,
    locationSlug
  } = props;

  const handleReservationButtonClick = throttle(() => {
    openReservationsModal(locationSlug);
  }, 500);

  let isModal = false;
  let attributes = {
    type: 'button',
    tabIndex: '0',
    altColor: button_alt_colors,
    className: 'locationListItem-customButton locationListItem-link',
    addTopMargin: true
  };

  switch (button_type) {
    case buttonTypes.URL:
      isModal = false;
      attributes = {
        ...attributes,
        href: url,
        target: url_target ? '_blank' : '_self'
      };
      break;
    case buttonTypes.RESERVATIONS:
      isModal = true;
      attributes = {
        ...attributes,
        onClick: handleReservationButtonClick
      };
      break;
    case buttonTypes.ONLINE_ORDERING:
      if (url_target) {
        isModal = false;
        attributes = {
          ...attributes,
          href: `https://ordering.chownow.com/order/${chowNowId}/locations`,
          target: '_blank',
          isExternal: true
        };
      } else {
        isModal = true;
        attributes = {
          ...attributes,
          // Binds order online modal's trigger by classname
          className: `${attributes.className} chownow-order-online`
        };
      }
      break;
    default:
      attributes = {};
  }

  return isModal ? (
    <Button {...attributes} label={label} />
  ) : (
    <LinkButton {...attributes} label={label} />
  );
};

MultiButtonComponent.propTypes = {
  chowNowId: PropTypes.string,
  locationSlug: PropTypes.string.isRequired,
  ...multiButtonProps
};

MultiButtonComponent.defaultProps = {
  chowNowId: null,
  ...DEFAULT_MULTI_BUTTON_PROPS
};

export default MultiButtonComponent;
