import * as Sentry from '@sentry/browser';
import {
  APPNAME,
  PRODUCTION,
  STAGING,
  DEVELOPMENT,
  UNKNOWN,
  SENTRY_DSN
} from './constants';

import { beforeSend } from '../utils/sentryUtils';

function getEnvironment() {
  // Determine what environment we're in by looking at the URL.

  let environment, host;

  try {
    host = window.location.host;
  } catch (e) {
    host = '';
  }

  if (host === '') {
    environment = UNKNOWN;
  } else if (
    ['localtest.me', 'localhost'].some(substring => host.includes(substring))
  ) {
    environment = DEVELOPMENT;
  } else if (host.includes('bentosandbox.com')) {
    environment = STAGING;
  } else {
    // Production is more than just getbento.com since client sites can have their own domain.
    // Therefore, anything that doesn't fall under STAGING, DEVELOPMENT, or UNKNOWN is PRODUCTION.
    environment = PRODUCTION;
  }

  return environment;
}

function getRelease() {
  // The git tag inserted into index.html by CI for successful master branch builds.
  try {
    const tag = document
      .querySelector(`meta[name="getbento/${APPNAME}"]`)
      .getAttribute('content');
    return `${APPNAME}@${tag}`;
  } catch (e) {
    return `${APPNAME}@pre-production`;
  }
}

export function initializeSentry() {
  Sentry.init({
    // add DEVELOPMENT into the array below if you want sentry logging for local development
    dsn: [PRODUCTION, STAGING].includes(getEnvironment()) ? SENTRY_DSN : '',
    environment: getEnvironment(),
    release: getRelease(),
    beforeSend
  });

  // Sentry.captureException(new Error('Exception: It works!'));
  // Sentry.captureMessage('Message: It works!');
}
