import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';

import {
  LocationsContainer,
  LocationsContainerMobile
} from 'styles/styledComponents';

class MobileViewContainer extends React.Component {
  render() {
    const { children, id } = this.props;
    return (
      <React.Fragment>
        <MediaQuery query="(min-width: 768px)">
          {matches => {
            if (matches) {
              return (
                <LocationsContainer id={id}>{children}</LocationsContainer>
              );
            } else {
              return (
                <LocationsContainerMobile id={id}>
                  {children}
                </LocationsContainerMobile>
              );
            }
          }}
        </MediaQuery>
      </React.Fragment>
    );
  }
}

MobileViewContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired
};

export default MobileViewContainer;
