import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import MapboxGeocoder from 'components/MapboxGeocoder';

import {
  SearchContainerSection,
  SearchError,
  SearchResetSection
} from 'styles/styledComponents';

class SearchComponent extends React.Component {
  renderLocationNearbyMessageSection() {
    if (this.props.locationNearby) {
      return;
    }

    return (
      <SearchError className="searchErrors" id="searchErrorsMessage">
        We could not find any locations in your area. Please try another search.
      </SearchError>
    );
  }

  renderButtonSection() {
    const { handleManualSearch } = this.props;

    return (
      <SearchResetSection>
        <Button
          type="button"
          handleClick={handleManualSearch}
          aria-label="Search Location"
          label="Search"
          addTopMargin
        />
      </SearchResetSection>
    );
  }

  render() {
    const { isSearchUsable } = this.props;

    return (
      <SearchContainerSection className="searchContainer">
        <MapboxGeocoder isSearchUsable={isSearchUsable} />
        {isSearchUsable && (
          <React.Fragment>
            {this.renderButtonSection()}
            {this.renderLocationNearbyMessageSection()}
          </React.Fragment>
        )}
      </SearchContainerSection>
    );
  }
}

SearchComponent.propTypes = {
  handleManualSearch: PropTypes.func.isRequired,
  locationNearby: PropTypes.bool.isRequired,
  isSearchUsable: PropTypes.bool.isRequired
};

export default SearchComponent;
