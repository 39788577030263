import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'styles/styledComponents';

import {
  MAPBOX_GEOCODER,
  MAPBOX_GEOCODER_INPUT_ID,
  MAPBOX_GEOCODER_LABEL,
  MAPBOX_GEOCODER_NOT_AVAILABLE_MESSAGE
} from 'constants/mapbox';

const MapboxGeocoder = props => {
  const { isSearchUsable } = props;

  const renderLabelOrNotAvailableMessage = () => {
    return (
      <React.Fragment>
        {isSearchUsable ? (
          <Label htmlFor={MAPBOX_GEOCODER_INPUT_ID}>
            {MAPBOX_GEOCODER_LABEL}
          </Label>
        ) : (
          <p>
            <small>{MAPBOX_GEOCODER_NOT_AVAILABLE_MESSAGE}</small>
          </p>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderLabelOrNotAvailableMessage()}
      <div id={MAPBOX_GEOCODER}></div>
    </React.Fragment>
  );
};

MapboxGeocoder.propTypes = {
  isSearchUsable: PropTypes.bool.isRequired
};

export default MapboxGeocoder;
